<template>
  <div class="accord group cursor-pointer shadow-lg rounded-r-lg">
    <router-link
      :to="{
        name: 'accordDisplay',
        params: {
          organisationId: parentId,
          assetId: id,
        },
      }"
    >
      <div class="flex flex-col k1:flex-row">
        <div class="relative flex justify-center">
          <CompanyLogo :organisationImg="organisationImg" />
          <div class="absolute top-0 w-full bg-titleBackground/40">
            <div class="text-white p-2">From {{ countries }}</div>
          </div>
        </div>

        <div class="w-full px-6 py-4">
          <div class="flex flex-col gap-2 h-full">
            <div class="flex justify-between">
              <div class="flex font-bold">{{ title }}</div>
              <!--            <img-->
              <!--              src="/eqrcmedals_coin_level1.svg"-->
              <!--              class="award w-10 h-10"-->
              <!--              alt=""-->
              <!--            />-->
            </div>

            <!--          <div class="text-xs text-gray-400">By .... from database ?</div>-->
            <div class="flex flex-col gap-5">
              <div
                style="
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ description }}
              </div>
              <div class="text-gray-600 text-xs">
                From: {{ start }}
                <span class="pl-12" v-if="end"> To: {{ end }} </span>
              </div>
            </div>
            <div class="flex mt-auto">
              <div class="flex flex-row gap-1 align-center flex-wrap">
                <template
                  v-for="key in Object.keys(availablePrinciples)"
                  :key="key"
                  class="flex"
                >
                  <div
                    v-if="principles && principles.includes(key)"
                    class="flex flex-row group/principle"
                  >
                    <div
                      class="h-3 w-5 rounded-full self-center"
                      :class="{
                        ['bg-' + availablePrinciples[key].colorClass]: true,
                      }"
                    ></div>
                    <p class="pl-1 text-xs self-center">
                      {{ availablePrinciples[key].name }}
                    </p>
                    <div
                      class="hidden group-hover/principle:block popUp hover:!hidden"
                    >
                      <p>{{ availablePrinciples[key].info }}</p>
                    </div>
                  </div>
                </template>
              </div>
              <div class="ml-auto self-center flex-shrink-0">
                <SeeMoreButton></SeeMoreButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { capitalize } from "vue";
import { useDocument } from "@/composables/useDocument";
import { useRouter } from "vue-router";
import SeeMoreButton from "@/components/layout/layoutComponets/SeeMoreButton.vue";
import availablePrinciples from "./principles.js";
import CompanyLogo from "@/components/accords/CompanyLogo.vue";

export default {
  name: "Accord",
  components: { CompanyLogo, SeeMoreButton },
  props: {
    dataType: {
      type: String,
      required: true,
      validator: (v) => v && capitalize(v) === v,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const { accord, deleteAccord } = useDocument(props.dataType, props.id);
    // console.log(accord.value);
    const { title, countries, description, start, end, principles, parentId } =
      accord.value;

    const { organisation } = useDocument("Organisation", parentId);
    const organisationImg = organisation.value.imageUrl;

    const onDelete = () => deleteAccord().then(() => router.push("/accords"));

    return {
      onDelete,
      title,
      countries,
      description,
      start,
      end,
      principles,
      organisationImg,
      availablePrinciples,
      parentId,
    };
  },
};
</script>

<style scoped></style>
